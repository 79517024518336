import Vue from 'vue'
import App from './App.vue'
//import './registerServiceWorker'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import css from './assets/css/style.css'

import Amplify, * as AmplifyModules from 'aws-amplify'
import { Auth } from "aws-amplify";
import { AmplifyPlugin } from 'aws-amplify-vue'
import awsExports from './aws-exports'
import { ENV, ENVPROD, OAUTH_DOMAIN, HOSTNAME_COM } from './mixins/utils'

import InfiniteLoading from 'vue-infinite-loading'

Vue.config.productionTip = false

if (ENV === ENVPROD) {
  awsExports['oauth']['domain'] = OAUTH_DOMAIN;
}
Amplify.configure(awsExports)
Vue.use(AmplifyPlugin, AmplifyModules)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(InfiniteLoading)

// setup fortawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
//import { fab } from '@fortawesome/free-brands-svg-icons';
//import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);
// fortawesome

const languageDict = {
  ja: {
    'Sign in to your account': 'ログイン',
    'Create a new account': 'アカウントを作成',
    'Username': 'ユーザ名',
    'Email': 'メールアドレス',
    'Sign In': 'ログイン',
    'Enter your username': 'ユーザ名を入力してください',
    'Password': 'パスワード',
    'Reset your password': 'パスワードリセット',
    'Enter your email': 'メールアドレスを入力してください',
    'Enter your password': 'パスワードを入力してください',
    'Forgot your password? ': 'パスワードを忘れた場合',
    'Reset password': 'パスワードのリセット',
    'No account? ': 'まだアカウントがない場合 ',
    'Have an account? ': 'アカウントがある場合 ',
    'Create Account': 'アカウント作成',
    'Create account': 'アカウント作成',
    'Sign in': 'ログインに戻る',
    'Back to Sign In': 'ログインに戻る',
    'Send Code': '検証コードを送信',
    'Resend Code': '検証コードを再送信',
    'Code': '検証コード',
    'New Password': '新しいパスワード',
    'Submit': '送信',
    'Confirm Sign Up': 'アカウントを作成',
    'Confirmation Code': '検証コード',
    'Lost your code? ': '検証コードを忘れた場合',
    'An account with the given email already exists.': 'アカウントは既に存在します。',
    'Confirm': '確認',
    'Sign Out': 'ログアウト',

    'User does not exist.': 'ユーザが存在しません。',
    'Password cannot be empty': 'パスワードを入力してください。',
    'Invalid verification code provided, please try again.': '検証コードが有効ではありません。',
    'Invalid code provided, please request a code again.': '検証コードが有効ではありません。',
    'Username cannot be empty': 'メールアドレスを入力してください。',
    'Custom auth lambda trigger is not configured for the user pool.': 'パスワードを入力してください。',
    'Incorrect username or password.': 'メールアドレスまたはパスワードが誤っています。',
    "1 validation error detected: Value at 'userName' failed to satisfy constraint: Member must have length less than or equal to 128": 'メールアドレスは128文字以下です。',
    'Username/client id combination not found.': '検証に失敗しました。',
    "1 validation error detected: Value at 'username' failed to satisfy constraint: Member must have length less than or equal to 128": 'メールアドレスは128文字以下です。',
    'The following fields must be completed: Email, Password': 'メールアドレスとパスワードを入力してください。',
    'The following fields must be completed: Password': 'パスワードを入力してください。',
    'The following fields must be completed: Email': 'メールアドレスを入力してください。',
    'Invalid email address format.': 'メールアドレスの書式が誤っています。',
    '1 validation error detected: Value at \'password\' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$': 'パスワードの書式が誤っています。',
    "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length less than or equal to 256; Value at 'username' failed to satisfy constraint: Member must have length less than or equal to 128": 'メールアドレスは128文字以下です。パスワードは256文字以下です。',
    'Confirmation code cannot be empty': '検証コードを入力してください。',
    'User cannot be confirmed. Current status is CONFIRMED': 'アカウントは登録済みです。',
    'Password did not conform with policy: Password not long enough': 'パスワードは8文字以上です。',
    'Password does not conform to policy: Password not long enough': 'パスワードは8文字以上です。',
    'Attempt limit exceeded, please try after some time.': '試行制限を超えました。しばらくしてから試してください。',
    'User is disabled.': 'ユーザーは無効化されています。',
  },
  'en-US': {
    'ERESA (イーリサ)｜Amazonリサーチ・分析ツールの決定版！': 'ERESA｜The definitive Amazon research and analysis tool!',
    'ERESA (イーリサ)は、Amazonで販売されている商品のデータ(ランキング・価格推移・価格比較)を簡単に確認、分析を行うことができます。': 'ERESA allows you to easily check and analyze data (rankings, price trends, price comparisons) on products sold on Amazon.',
  }
}
Object.assign(languageDict['ja'], require('./store/messages/ja/size.js').default);
Object.assign(languageDict['ja'], require('./store/messages/ja/App.js').default);
Object.assign(languageDict['ja'], require('./store/messages/ja/components/LineChart.js').default);
Object.assign(languageDict['ja'], require('./store/messages/ja/components/LockPro.js').default);
Object.assign(languageDict['ja'], require('./store/messages/ja/components/OfferList.js').default);
Object.assign(languageDict['ja'], require('./store/messages/ja/components/ResultList.js').default);
Object.assign(languageDict['ja'], require('./store/messages/ja/components/ResultListLite.js').default);
Object.assign(languageDict['ja'], require('./store/messages/ja/components/product/PriceTable.js').default);
Object.assign(languageDict['ja'], require('./store/messages/ja/components/product/ProductInfo.js').default);
Object.assign(languageDict['ja'], require('./store/messages/ja/components/product/RankingDrop.js').default);
Object.assign(languageDict['ja'], require('./store/messages/ja/components/settings/ProductDetailSettings.js').default);
Object.assign(languageDict['ja'], require('./store/messages/ja/components/settings/SearchConditions.js').default);
Object.assign(languageDict['ja'], require('./store/messages/ja/components/settings/DisplayOrders.js').default);
Object.assign(languageDict['ja'], require('./store/messages/ja/mixins/AIChat.js').default);
Object.assign(languageDict['ja'], require('./store/messages/ja/mixins/Shops.js').default);
Object.assign(languageDict['ja'], require('./store/messages/ja/views/KeywordMining.js').default);
Object.assign(languageDict['ja'], require('./store/messages/ja/views/SalesEstimation.js').default);
Object.assign(languageDict['ja'], require('./store/messages/ja/views/register/RegisterAccount.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/size.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/App.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/AiChatWindow.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/BrowsingHistoryPage.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/CandidateWindow.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/ConfirmPassword.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/FavoritesPage.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/ItemSelector.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/LockPro.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/LineChart.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/OfferList.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/PageControl.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/Product.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/SelectOrganization.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/SearchConditions.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/SearchConditionsPage.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/SearchControl.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/RangeCondition.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/ResultList.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/ResultListLite.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/ReviewList.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/TrackingList.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/TrackingValueCondition.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/product/BuyBoxRate.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/product/CalcProfit.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/product/ImageChanger.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/product/PriceInfo.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/product/PriceTable.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/product/PriceTableSm.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/product/ProductAiButtons.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/product/ProductInfo.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/product/ProductSellerList.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/product/RankingDrop.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/product/ShopLinks.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/product/ShopList.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/product/VariationList.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/settings/ProductDetailSettings.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/settings/SearchConditions.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/components/settings/DisplayOrders.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/mixins/AIChat.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/mixins/AiButtons.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/mixins/Shops.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/Account.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/Affiliate.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/AiAdvisor.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/CategoryRanking.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/ChangeMailAddress.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/ChangePassword.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/CodeSearch.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/Favorites.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/KeywordMining.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/Login.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/Offers.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/PageGenerator.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/ReviewResearch.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/SalesEstimation.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/Search.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/SearchLite.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/SellerResearch.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/SeoResearch.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/Tracking.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/TrackingDetail.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/admin/ExtStatus.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/register/ConfirmSignUp.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/register/RegisterAccount.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/register/ResetPassword.js').default);
Object.assign(languageDict['en-US'], require('./store/messages/com/views/register/ResetPasswordSubmit.js').default);

AmplifyModules.I18n.putVocabularies(languageDict)
const defaultLang = window.location.hostname === HOSTNAME_COM ? 'en-US' : 'ja';
AmplifyModules.I18n.setLanguage(defaultLang);

Auth.currentAuthenticatedUser({ bypassCache: true });

new Vue({
  router,
  store,
  css,
  render: h => h(App)
}).$mount('#app')
