export default {
  "Keepaステータス": "Keepa status",
  "拡張機能ステータス": "Extensions Status",
  "有効": "Enable",
  "無効": "Disable",
  "変更": "Change",
  "キャンセル": "Cancel",
  "変更します。よろしいですか？": "Do you want to update your status?",
  "残トークン：": "Tokens remaining:",
  "トークン履歴：": "Token history:",
  "残トークン": "Tokens remaining",
  "最新の情報に更新": "Refresh",
  "日時": "Date and time",
}
