import { Auth } from "aws-amplify";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "@/graphql/queries";
import awsExports from '@/aws-exports'

export default {
  methods: {
    async signIn(user, password) {
      const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
      if (cognitoUser != void 0) {
        if (user == void 0 || user === cognitoUser.attributes.email) {
          return;
        }
        await Auth.signOut();
      }
      if (user == void 0 || password == void 0) {
        return;
      }
      await Auth.signIn(user, password);
      await Auth.currentAuthenticatedUser().then(user => {
        this.$store.commit("setUser", user);
      });
    },
    async getGroups() {
      return await Auth.currentSession().then(user => {
        const { payload } = user.getIdToken()
        if(payload != void 0 && payload['cognito:groups']) {
          const groups = payload['cognito:groups'];
          if (groups != void 0) {
            return groups;
          }
        }
        return [];
      });
    },
    async isAdmin() {
      const groups = await this.getGroups();
      return groups.includes('EresaAdmin');
    },
    async isSns() {
      const groups = await this.getGroups();
      const poolsid = awsExports['aws_user_pools_id'];
      return groups.includes(`${poolsid}_Google`) || groups.includes(`${poolsid}_SignInWithApple`);
    },
    async isSubscriber(includeAdmin = true) {
      const q = await API.graphql(graphqlOperation(queries.isSubscriber, { includeAdmin }));
      return q.data.isSubscriber;
    },
    async isOemUser() {
      const groups = await this.getGroups();
      return groups.includes('OemUser');
    },
    async getSubscriberType(includeAdmin = true) {
      const q = await API.graphql(graphqlOperation(queries.getSubscriberType, { includeAdmin }));
      return JSON.parse(q.data.getSubscriberType);
    },
    async refreshToken() {
      /*const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => null);
      if (cognitoUser != void 0) {
        const currentSession = await Auth.currentSession();
        await cognitoUser.refreshSession(currentSession.refreshToken, () => {});
      }*/
    },
    async validateSubscriber() {
      const r = await this.isSubscriber();
      if (!r) {
        this.$store.commit("setIsSubscriber", false);
        this.$router.push({ name: "Default" });
        return;
      }
    },
    async validateSubscriberYear() {
      const r = await this.getSubscriberType(false);
      if (!r.isEresaProYear) {
        this.$store.commit("setIsSubscriber", false);
        this.$router.push({ name: "Default" });
        return;
      }
    },
    async validateOemUser() {
      const r = await this.isOemUser();
      if (!r) {
        this.$store.commit("setIsOemUser", false);
        this.$router.push({ name: "Default" });
        return;
      }
    },
    async validateAdmin() {
      const q = await API.graphql(graphqlOperation(queries.isAdmin));
      const r = q.data.isAdmin;
      if (!r) {
        this.$router.push({ name: "Default" });
        return;
      }
    },
  }
};
