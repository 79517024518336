<template>
  <div class="page-content">
    <div class="container">
      <div class="d-flex flex-wrap">
        <!--  カテゴリー -->
        <b-dropdown :text="category.name" split split-variant="outline-primary" variant="dark" class="dropdown-eresa m-2 d-none d-sm-inline-block">
          <b-dropdown-item v-for="item in categoryList" :key="item.key" @click="categorySelected(item)">
            {{ item.name }}
          </b-dropdown-item>
        </b-dropdown>
        <div class="year-switcher">
          <b-button variant="primary" class="bg-eresa" @click="decrementYear">&lt;</b-button>
          <span class="year">{{ currentYear }}</span>
          <b-button variant="primary" class="bg-eresa" @click="incrementYear">&gt;</b-button>
        </div>
      </div>
      
      <div v-if="list != void 0">
        <div class="card shadow border-0 pb-4 pl-4">
          <template v-for="(item, i) in list">
            <div :class="i > 0 ? 'd-flex flex-column flex-md-row justify-content-between mt-3 pt-3 border-top' : 'd-flex flex-column flex-md-row justify-content-between pt-3'" :key="`content-${i}`">
              <div>
                <div class="title">{{ item.month }}</div>
              </div>
              <div class="ml-md-auto pl-2 mr-4 d-flex align-items-center flex-end">
                <span v-if="item.createdAt != null">{{ item.createdAt }}</span>
                <span v-else>未登録</span>
              </div>
              <div class="mr-4 d-flex align-items-center flex-end justify-content-around">
                <b-button variant="white" class="text-eresa register mr-2" size="sm" @click="showImportItem(i)">{{ t('CSV登録') }}</b-button>
                <b-button variant="white" class="text-eresa delete" size="sm" @click="showDeleteItem(item)">{{ t('削除') }}</b-button>
                <input type="file" ref="fileInput" style="display: none" @change="loadFile($event,item,i)" />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!--<Loading v-else-if="loading"></Loading>-->
    
    <!-- deleteFav -->
    <b-modal ref="deleteConfirm" @ok="deleteArchive" :title="t('登録済みランキングを削除')" :ok-title="t('削除')" :cancel-title="t('キャンセル')">
      <div class="mt-4">
        <div class="mt-2">
          <div class="mt-2">{{ t('登録済みのランキングを削除します。よろしいですか？') }}</div>
          <span class="mt-2">{{ t('削除する対象年月') }} : {{ deleteItem.month }} </span>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
//import {Auth, API, graphqlOperation} from 'aws-amplify';
//import Loading from "@/components/Loading.vue";
import AuthUtil from "@/mixins/authutil";
import Utils from "@/mixins/utils";
import { CategoryRankingArchive } from "@/store/CategoryRankingArchive.js";
import { Category } from "@/mixins/Category.js";

const categoryNameUnselected = 'カテゴリーを選択してください';

export default {
  name: 'Archive',
  components: {
  },
  mixins: [AuthUtil, Utils],
  data() {
    return {
      category: { id: null, name: this.t(categoryNameUnselected), domain: null },
      categoryList: null,
      currentYear: new Date().getFullYear(),
      list: null,
      deleteItem: {id: '', month: '', domain: ''},
    }
  },
  async created() {
    const domain = this.$store.getters.getDomain;
    await this.initializeCategoryList(domain);
  },
  async mounted() {
    await this.validateAdmin();
  },
  computed: {
    domain() {
      return this.$store.getters.getDomain;
    },
  },
  methods: {
    async initializeCategoryList(domain) {
      const categories = await Category.fetchCategoryListForCategoryRanking(this.$store.getters.getDomain);
      this.categoryList = [];
      for (const key in categories) {
        const name = categories[key].name;
        this.categoryList.push({ key, name, domain });
      }
    },
    showImportItem(index) {
      this.$refs.fileInput[index].click();
    },
    initializeRegisteredList() {
      let tmpList = [];
      for (let i = 1; i <= 12; i++) {
        let month = `${this.currentYear}-${String(i).padStart(2, '0')}`; // "yyyy-MM" 形式
        tmpList.push({ month: month, archiveId: null, createdAt: null });
      }

      if (this.archiveData != void 0) {
        const registeredMonth = this.archiveData.registeredMonths.filter(item => {
          return item.month.startsWith(this.currentYear) && item.domain == this.domain;
        });

        registeredMonth.forEach(item => {
          let monthIndex = parseInt(item.month.split('-')[1], 10) - 1;
          if (monthIndex >= 0 && monthIndex < 12) {
            tmpList[monthIndex].archiveId = item.archiveId;
            tmpList[monthIndex].domain = item.domain;
            tmpList[monthIndex].createdAt = this.formatDate(item.createdAt);
          }
          // item.__typename は削除
          delete item.__typename;
        });
      }

      this.list = tmpList;
    },
    async categorySelected(item) {
      const categoryId = item.key;
      const target = this.categoryList.filter(item => item.key === categoryId);
      
      if (target.length > 0) {
        this.category = { id: categoryId, name: target[0].name, domain: target[0].domain };
        const res = await CategoryRankingArchive.getCategoryRankingRegisteredMonths(categoryId);
        this.archiveData = res;
        this.initializeRegisteredList();
      }
    },
    showDeleteItem(item) {
      // 削除確認
      this.deleteItem = item;
      this.$refs.deleteConfirm.show();
    },
    async deleteArchive() {
      // 削除処理
      const deleteTarget = this.deleteItem;
      
      // 削除対象月のデータを削除
      const registeredMonths = this.archiveData.registeredMonths.filter(item => {
        return item.month != deleteTarget.month || item.domain != deleteTarget.domain;
      });

      const updateArchiveData = {
        categoryId: this.category.id,
        registeredMonths: registeredMonths
      }

      this.archiveData = await CategoryRankingArchive.updateCategoryRankingRegisteredMonths(updateArchiveData);
      this.$refs.deleteConfirm.hide();
      this.initializeRegisteredList();

      // 削除対象月のアーカイブデータを削除
      await CategoryRankingArchive.deleteCategoryRankingArchive(deleteTarget.archiveId);
    }
    ,
    async loadFile(event,item) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const content = e.target.result;
          const asins = this.parseCSV(content);
          const category = { 
            categoryId: this.category.id,
            domain: this.domain,
            month: item.month,
            asins: asins
          };
          this.registerCategoryRanking(category);
          event.target.value = '';
        };
        reader.readAsText(file);        
      }
    },
    async registerCategoryRanking(category) {
      const registeredData = await CategoryRankingArchive.createCategoryRankingArchive(category);
      const registeredMonth = 
        {
          archiveId: registeredData.id,
          month: registeredData.month,
          domain: registeredData.domain,
          createdAt: registeredData.createdAt,
        };

      // もし登録済み月リストに同じ月の同じドメインのデータがあればデータを置き換える。なければ追加する
      let registeredMonths = [];

      if(this.archiveData != void 0) {
        let existsMonth = false;
        registeredMonths = this.archiveData.registeredMonths.map(item => {
          if (item.month == registeredMonth.month && item.domain == registeredMonth.domain) {
            existsMonth = true;
            return registeredMonth;
          }
          return item;
        });
        if (!existsMonth) {
          registeredMonths.push(registeredMonth);
        }  
      } else {
        registeredMonths = [registeredMonth];
      }

      const archiveData = {
        categoryId: category.categoryId,
        registeredMonths: registeredMonths
      }

      // registeredMonthsから__typenameを削除
      registeredMonths.forEach(item => {
        delete item.__typename;
      }); 

      this.archiveData = await CategoryRankingArchive.upsertCategoryRankingRegisteredMonths(archiveData);
      this.initializeRegisteredList();
    },

    parseCSV(csv) {
      const lines = csv.split('\n');
      return lines.slice(1).map(line => {
        const trimmedLine = line.trim();
        return trimmedLine.substring(1, trimmedLine.length - 1);
      }).filter(line => line.length > 0);
    },

    
    incrementYear() {
      this.currentYear++;
      if( this.registerCategoryRanking ) {
        this.initializeRegisteredList();
      }
    },
    decrementYear() {
      this.currentYear--;
      if( this.registerCategoryRanking ) {
        this.initializeRegisteredList();
      }
    },

    formatDate(dateString) {
      if (!dateString) return '';

      // UTC日付を日本時間に変換
      let date = new Date(dateString);

      // 日本時間をフォーマット
      let formatter = new Intl.DateTimeFormat('ja-JP', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });

      return formatter.format(date);
    },
  }
}
</script>
<style scoped>
.year-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
}

.year {
  margin: 0 10px;
  font-size: 1.5em;
}
.register, .delete {
  border-color: #ddd;
  font-size: 9pt;
  padding: 4px 8px 4px 8px;
  margin: 4px 0px 4px 0px;
}
</style>
